/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <!-- v-if="bankOptions && currencyOptions" -->
    <deposits-list-filters
      :type-filter.sync="typeFilter"
      :reference-filter.sync="referenceFilter"
      :username-filter.sync="usernameFilter"
      :status-filter.sync="statusFilter"
      :currency-filter.sync="currencyFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :bank-filter.sync="bankFilter"
      :bank-account-filter.sync="bankAccountFilter"
      :member-acount-no-filter.sync="memberAccountNoFilter"
      :tags-filter.sync="tagsFilter"
      :reference-no-filter.sync="referenceNoFilter"
      :type-options.sync="typeOptions"
      :status-options="statusOptions"
      :bank-options.sync="bankOptions"
      :tags-options.sync="tagsOptions"
      :account-number-filter.sync="accountNumberFilter"
      @refetch-data="refetchData"
    />

    <analytic-deposit
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :refresh-top-deposit-analytic.sync="refreshTopDepositAnalytic"
    />

    <b-card no-body class="mb-0">
      <b-overlay :show="show" variant="transparent" no-wrap />
      <div class="p-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            {{ $t("Pending") }}:
            <span
              class="text-primary mx-50 cursor-pointer"
              @click="setStatus(1)"
            >
              {{ processing }}
            </span>
            | {{ $t("Auto refresh") }}:
            <span class="mx-1"> {{ timerCount }} </span>
            <b-form-checkbox
              v-model="timerEnabled"
              :checked="timerEnabled"
              class="custom-control-primary"
              name="check-button"
              switch
              inline
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <span class="cursor-pointer" @click="reloadData">
              <feather-icon icon="RotateCwIcon" size="14" />
            </span>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="$can('export', 'deposit')"
            cols="12"
            md="3"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="!showLoadingExport"
              variant="primary"
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled class="mr-1">
              <b-spinner small />
              <feather-icon icon="DownloadIcon" size="16" />
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                ref="exportButton"
                :data="data"
                :columns="columns"
                :file-name="exportFiletitle"
                :file-type="'xlsx'"
                :sheet-name="exportFileSheetName"
              >
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDepositListTable"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        class="position-relative table-white-space max-height-table"
        :items="fetchDeposits"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ref)="data">
          <b-link @click="showDepositDetail(data.item)">
            {{ data.item.ref }}
            <feather-icon
              v-if="data.item.local_deposit_attachements.length > 0"
              icon="PaperclipIcon"
            />
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
            v-if="data.item.user"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.username }}
          </b-link>
        </template>

        <!-- Column: mã đại lý -->
        <template #cell(affiliate_id)="data">
          <b-link
              v-if="data.item.user"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.affiliate_id }}
          </b-link>
        </template>

        <template #cell(tags)="data">
          <b-badge
            v-if="resolveTags(data.item.user)"
            :style="`background-color: ${resolveTags(data.item.user).color}`"
          >
            <feather-icon icon="TagIcon" class="mr-25" />
            {{ resolveTags(data.item.user).name }}
          </b-badge>
        </template>

        <template #cell(fullName)="data">
          <span v-if="data.item.user">{{ data.item.user.name }}</span>
        </template>

        <template #cell(type)="data">
          {{ data.item.txn_type.name }}
        </template>

        <template #cell(companyBankAccountCode)="data">
          {{
            data.item.v_bank
              ? resolveVBank(
                  data.item.v_bank.account,
                  data.item.v_bank.bank.code
                )
              : ""
          }}
        </template>

        <template #cell(fee)="data">
          <span class="text-primary">{{
            numberFormat(data.item.fee / 1000)
          }}</span>
        </template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(data.item.amount / 1000)
          }}</span>
        </template>

        <template #cell(netAmount)="data">
          <span class="text-primary">{{
            numberFormat((data.item.amount - data.item.fee) / 1000)
          }}</span>
        </template>

        <template #cell(currency)="data">
          {{ data.item.currency }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.txn_status.id).variant}`"
            />
            {{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-success
            :title="$t('Approve')"
            v-if="
              data.item.type != 1 &&
              data.item.stat == 1 &&
              data.item.type != 4 &&
              $can('update', 'localdepositstatus')
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon btn-sm mr-50"
            @click="approveDepositModal(data.item)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject ')"
            v-if="
              data.item.type != 1 &&
              data.item.stat == 1 &&
              data.item.type != 4 &&
              $can('update', 'localdepositstatus')
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon btn-sm mr-50"
            @click="rejectDepositModal(data.item)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Upload')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon btn-sm mr-50"
            @click="showUploadBill(data.item.ref)"
          >
            <feather-icon icon="UploadIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Detail')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon btn-sm"
            @click="showDepositDetail(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDeposits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Reject Deposit Modal -->
    <reject-deposit-modal
      :deposit-data.sync="depositData"
      @refetch-data="refetchData"
    />
    <!-- Approve Deposit Modal -->
    <approve-deposit-modal
      :deposit-data.sync="depositData"
      @refetch-data="refetchData"
    />
    <!-- Detail Modal -->
    <deposit-detail-modal :deposit-data.sync="depositData" />

    <!-- Upload Bill Modal -->
    <upload-bill-modal :reference.sync="reference" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BFormCheckbox,
  BOverlay,
  BSpinner,
  BPagination,
  BBadge,
  BTooltip,
  VBTooltip,
  VBModal,
} from "bootstrap-vue";
import {
  resolveVBank,
  avatarText,
  numberFormat,
  formatDateTime,
} from "@core/utils/filter";
import { onUnmounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import DepositsListFilters from "./DepositsListFilters.vue";
import useDepositsList from "./useDepositsList";
import depositStoreModule from "./depositStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import tagStoreModule from "@/views/apps/user/tag/tagStoreModule";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";
import AnalyticDeposit from "@/views/payments/analytic/AnalyticDeposit.vue";

export default {
  components: {
    DepositsListFilters,
    RejectDepositModal: () => import("./RejectDepositModal.vue"),
    ApproveDepositModal: () => import("./ApproveDepositModal.vue"),
    DepositDetailModal: () => import("./DepositDetailModal.vue"),
    UploadBillModal: () =>
      import("@/views/payments/upload-bill/UploadBillModal.vue"),
    AnalyticDeposit,
    VueExportExcel,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BFormCheckbox,
    BOverlay,
    BPagination,
    BSpinner,
    BBadge,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      depositData: null,
      processing: 0,
      timerEnabled: false,
      timerCount: 30,
      show: false,
      showLoadingExport: false,
      columns: [
        { label: "#", field: "id" },
        { label: "Transaction ID", field: "ref" },
        { label: "Username", field: "user", dataFormat: this.showUsername },
        // { label: 'Tags', field: '' },
        { label: "Type", field: "type", dataFormat: this.resolveType },
        { label: "Full Name", field: "user", dataFormat: this.showName },
        // {
        //   label: "Company Bank Account Code",
        //   field: "v_bank",
        //   dataFormat: this.resolveVBankExport,
        // },
        { label: "Member Bank Acc Name", field: "account_name" },
        { label: "Member Bank Acc No", field: "account_number" },
        { label: "Member Bank Ref No", field: "bank_ref" },
        { label: "Currency", field: "currency" },
        {
          label: "Amount",
          field: "amount",
          dataFormat: (value) => value / 1000,
        },
        { label: "Fee", field: "fee", dataFormat: (value) => value / 1000 },
        {
          label: "Status",
          field: "stat",
          dataFormat: (value) => this.resolveStatus(value).label,
        },
        {
          label: "Created At",
          field: "created_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        {
          label: "Updated At",
          field: "updated_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
      ],
      data: [],
      exportFiletitle: `Deposits-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "Deposits Transactions",
      refreshTopDepositAnalytic: false,
      reference: null,
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          // eslint-disable-next-line no-plusplus
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            // eslint-disable-next-line no-plusplus
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.reloadData();
          this.timerCount = 30;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  // beforeMount() {
  //   this.getProcessing();
  // },
  methods: {
    rejectDepositModal(depositData) {
      this.depositData = depositData;
      this.$bvModal.show("modal-reject-deposit");
    },
    approveDepositModal(depositData) {
      this.depositData = depositData;
      this.$bvModal.show("modal-approve-deposit");
    },
    showDepositDetail(depositData) {
      this.depositData = depositData;
      this.$bvModal.show("modal-deposit-detail");
    },
    showUploadBill(transactionId) {
      this.reference = transactionId;
      this.$bvModal.show("modal-upload-bill");
    },
    getProcessing() {
      this.$store
        .dispatch("payment-deposit/fetchDeposits", { stat: 1 })
        .then((response) => {
          const { total } = response;
          this.processing = total;
        });
    },
    setStatus(status) {
      this.statusFilter = status;
    },
    reloadData() {
      this.show = true;
      this.refetchData();
      this.refreshTopDepositAnalytic = !this.refreshTopDepositAnalytic;
      // setTimeout(() => {
      //   this.getProcessing();
      //   /* if (this.timerEnabled) {
			// 		this.timerEnabled = false
			// 	} */
      // }, 1500);
      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    priceFormat(value) {
      return `$ ${value}`;
    },
    resolveVBankExport(value) {
      return value ? resolveVBank(value.account, value.bank.code) : "";
    },
    showUsername(value) {
      return value ? value.username : "";
    },
    showName(value) {
      return value ? value.name : "";
    },
    async getExportData() {
      await store
        .dispatch("payment-deposit/exportDeposits", {
          // page: pageNumber,
          type: this.typeFilter,
          username: this.usernameFilter,
          reference: this.referenceFilter,
          fromdate: this.fromdateFilter,
          todate: this.todateFilter,
          bankId: this.bankFilter,
          stat: this.statusFilter,
        })
        .then((response) => {
          console.log(123,response)
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          // const { deposits } = response
          // this.data.push(...deposits)
        });
    },
    async exportData() {
      this.showLoadingExport = true;
      // this.data = []
      // const totalPage = Math.ceil(this.totalDeposits / this.perPage)
      // for (let i = 1; i <= totalPage; i++) {
      await this.getExportData();
      await this.delay(2000);
      // }
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    const statusOptions = ref([]);
    const bankOptions = ref([]);
    const tagsOptions = ref([]);
    const currencyOptions = ref([]);
    const typeOptions = ref([]);
    const depositsId =ref(1);
    const PAYMENT_DEPOSIT_STORE_MODULE_NAME = "payment-deposit";
    const LOCAL_SETTING_BANK_STORE_MODULE_NAME = "local-setting-bank";

    // Register module
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule(PAYMENT_DEPOSIT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_DEPOSIT_STORE_MODULE_NAME,
        depositStoreModule
      );
    if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
      store.registerModule(
        LOCAL_SETTING_BANK_STORE_MODULE_NAME,
        bankStoreModule
      );
    if (!store.hasModule("app-user-tag"))
      store.registerModule("app-user-tag", tagStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_DEPOSIT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_DEPOSIT_STORE_MODULE_NAME);
      if (store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
        store.unregisterModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME);
    });

    const resolveTags = (user) => {
      if (user) {
        if (user.user_tags.length > 0) {
          return user.user_tags[0].tag;
        }
      }
      return "";
    };

    const {
      fetchDeposits,
      tableColumns,
      perPage,
      currentPage,
      totalDeposits,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepositListTable,
      refetchData,

      // UI
      resolveStatus,
      resolveType,

      // Extra Filters
      typeFilter,
      referenceFilter,
      usernameFilter,
      statusFilter,
      currencyFilter,
      fromdateFilter,
      todateFilter,
      bankFilter,
      bankAccountFilter,
      memberAccountNoFilter,
      accountNumberFilter,
      tagsFilter,
      referenceNoFilter,
    } = useDepositsList();

    // store.dispatch("local-setting-currency/fetchCurrencies").then((response) => {
    //   if (response.data.code == "00") {
    //     currencyOptions.value = response.data.data;
    //   }
    // });

    store.dispatch("local-setting-currency/fetchPaymentType",{
      id: depositsId.value
    }).then((response) => {
      typeOptions.value = response.data?.data?.type_list.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

    store.dispatch("local-setting-bank/fetchBanks", {}).then((response) => {
      bankOptions.value = response;
    });

    store.dispatch("app-user-tag/fetchTagTypesFilter", {}).then((response) => {
      const { tagTypes } = response;
      tagsOptions.value = tagTypes;
    });

    store
        .dispatch("local-setting-currency/fetchOptionStatus")
        .then((response) => {
          statusOptions.value = response.data.data.map((val) => {
            return {
              label: val.name,
              value: val.id,
            };
          });
        });

    return {
      fetchDeposits,
      tableColumns,
      perPage,
      currentPage,
      totalDeposits,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepositListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,

      // UI
      resolveStatus,

      // Extra Filters
      typeFilter,
      referenceFilter,
      usernameFilter,
      statusFilter,
      currencyFilter,
      fromdateFilter,
      todateFilter,
      bankFilter,
      bankAccountFilter,
      memberAccountNoFilter,
      tagsFilter,
      referenceNoFilter,
      accountNumberFilter,
      typeOptions,
      statusOptions,
      bankOptions,
      tagsOptions,
      currencyOptions,

      formatDateTime,
      resolveVBank,
      resolveType,
      resolveTags,
    };
  },
};
</script>